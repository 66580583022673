<template>
    <div class="fitbox-side-box">
        <div class="fitbox-side-head">
            <h3 class="fitbox-side-title">资讯图册</h3>
            <div class="fitbox-side-tool">
                共&nbsp;{{ detail.imgarr.length }}&nbsp;张图片
            </div>
        </div>
        <div class="fitbox-side-body">
            <el-image
                style="width: 245px; height: 245px"
                :src="detail.imgarr[0].url"
                :preview-src-list="detail.imgarr.map(item => item.url)"
            />
            <div class="between-item">
                <span>定价</span>
                <b>{{ detail.yen }}日元 （{{ detail.rmb }}元）</b>
            </div>
            <div class="between-item">
                <span>出荷</span>
                <b>{{ detail.publishtype === '1' ? '未定' : (`${detail.publishyear}年` + (detail.publishtime === 'q0' ? '未定' : detail.publishtime)) }}</b>
            </div>
            <div class="between-item">
                <span>制作</span>
                <b>kamisoul</b>
            </div>
            <div class="between-item">
                <span>系列</span>
                <b>{{ detail.seriestitle }}</b>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'sideImgList',
    props: {
        detail: {
            type: Object,
            default: null
        }
    }
}
</script>
<style lang="scss" scoped>
.fitbox-side-body {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 285px;
        left: 0;
        width: 100%;
        height: 10px;
        background-color: $grayest;
    }
}
.el-image {
    margin-bottom: 50px;
}
</style>
