<template>
    <div v-if="detail.information" class="fitbox-side-box">
        <div class="fitbox-side-head">
            <h3 class="fitbox-side-title">同系列产品</h3>
        </div>
        <div class="fitbox-side-body">
            <router-link :to="`/news/detail?id=${detail.information.informationid}`">
                <img class="detail-side-img" :src="detail.information.img" width="245" height="245" alt="detail.information.title">
                <span class="detail-side-name">{{ detail.information.title }}</span>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: 'sideInformation',
    props: {
        detail: {
            type: Object,
            default: null
        }
    }
}
</script>
<style lang="scss" scoped>
</style>
